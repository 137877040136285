/* ---------------- */
/* ----- GLITCH ----  */
/* ------------------- */
@import url("https://fonts.googleapis.com/css?family=Montserrat:100");
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300&display=swap');

@font-face {
  font-family: fontPersonalizzato;
  src: url(../../public/font/OceanRush.otf);
}

#wrapper h1 {
  /* font-family: "Montserrat", sans-serif; */
  font-family: fontPersonalizzato;
}

.wrapper2 h2 {
  /* font-family: "Montserrat", sans-serif; */
  font-family: 'Teko', sans-serif;
  letter-spacing: 0.2em;
  margin-bottom: -40px !important;
}


#wrapper {
  text-align: center;
  padding-top: 30px;
}

.sub {
  position: relative;
  z-index: 2;
  color: #64dcdc;
  letter-spacing: 0.2em;
  font-size: 16px;
  padding-right: 20px;

}

.sub2{
  font-size: 13px;
  letter-spacing: 0.3em;
  color: #64dcdc;
}

/* Our mixin positions a copy of our text
directly on our existing text, while
also setting content to the appropriate
text set in the data-text attribute. */
.titolo_home{
  font-size: 5em;
  margin-bottom: -5px;
}
.titolo_bio{
  font-size: 3.4em;
  margin-top: 2vh;
  font-family: fontPersonalizzato;
}
.glitch {
  position: relative;
  color: white;
 
  /* letter-spacing: 0.4em; */
  /* Animation provies a slight random skew. Check bottom of doc
  for more information on how to random skew. */
  animation: glitch-skew 1s infinite linear alternate-reverse;
}
.glitch::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  left: 2px;
  text-shadow: -2px 0 #ff00c1;
  /* Creates an initial clip for our glitch. This works in
  a typical top,right,bottom,left fashion and creates a mask
  to only show a certain part of the glitch at a time. */
  clip: rect(44px, 450px, 56px, 0);
  /* Runs our glitch-anim defined below to run in a 5s loop, infinitely,
  with an alternating animation to keep things fresh. */
  animation: glitch-anim 5s infinite linear alternate-reverse;
}
.glitch::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  left: -2px;
  text-shadow: -2px 0 #00fff9, 2px 2px #ff00c1;
  animation: glitch-anim2 1s infinite linear alternate-reverse;
}

/* Creates an animation with 20 steaps. For each step, it calculates 
a percentage for the specific step. It then generates a random clip
box to be used for the random glitch effect. Also adds a very subtle
skew to change the 'thickness' of the glitch.*/
@keyframes glitch-anim {
  0% {
    clip: rect(86px, 9999px, 82px, 0);
    transform: skew(0.3deg);
  }
  5% {
    clip: rect(78px, 9999px, 72px, 0);
    transform: skew(0.24deg);
  }
  10% {
    clip: rect(75px, 9999px, 7px, 0);
    transform: skew(0.22deg);
  }
  15% {
    clip: rect(2px, 9999px, 41px, 0);
    transform: skew(0.18deg);
  }
  20% {
    clip: rect(90px, 9999px, 97px, 0);
    transform: skew(0.36deg);
  }
  25% {
    clip: rect(68px, 9999px, 24px, 0);
    transform: skew(0.41deg);
  }
  30% {
    clip: rect(8px, 9999px, 43px, 0);
    transform: skew(0.8deg);
  }
  35% {
    clip: rect(43px, 9999px, 90px, 0);
    transform: skew(0.22deg);
  }
  40% {
    clip: rect(74px, 9999px, 73px, 0);
    transform: skew(0.15deg);
  }
  45% {
    clip: rect(92px, 9999px, 28px, 0);
    transform: skew(0.24deg);
  }
  50% {
    clip: rect(31px, 9999px, 75px, 0);
    transform: skew(0.85deg);
  }
  55% {
    clip: rect(91px, 9999px, 5px, 0);
    transform: skew(0.12deg);
  }
  60% {
    clip: rect(30px, 9999px, 88px, 0);
    transform: skew(0.76deg);
  }
  65% {
    clip: rect(29px, 9999px, 62px, 0);
    transform: skew(0.14deg);
  }
  70% {
    clip: rect(47px, 9999px, 19px, 0);
    transform: skew(0.9deg);
  }
  75% {
    clip: rect(21px, 9999px, 15px, 0);
    transform: skew(0.1deg);
  }
  80% {
    clip: rect(3px, 9999px, 78px, 0);
    transform: skew(0.99deg);
  }
  85% {
    clip: rect(95px, 9999px, 25px, 0);
    transform: skew(0.48deg);
  }
  90% {
    clip: rect(75px, 9999px, 90px, 0);
    transform: skew(0.78deg);
  }
  95% {
    clip: rect(66px, 9999px, 28px, 0);
    transform: skew(0.62deg);
  }
  100% {
    clip: rect(11px, 9999px, 79px, 0);
    transform: skew(0.4deg);
  }
}
@keyframes glitch-anim2 {
  0% {
    clip: rect(61px, 9999px, 16px, 0);
    transform: skew(0.62deg);
  }
  5% {
    clip: rect(71px, 9999px, 35px, 0);
    transform: skew(0.78deg);
  }
  10% {
    clip: rect(4px, 9999px, 17px, 0);
    transform: skew(0.04deg);
  }
  15% {
    clip: rect(40px, 9999px, 68px, 0);
    transform: skew(0.47deg);
  }
  20% {
    clip: rect(37px, 9999px, 60px, 0);
    transform: skew(0.31deg);
  }
  25% {
    clip: rect(48px, 9999px, 36px, 0);
    transform: skew(0.69deg);
  }
  30% {
    clip: rect(58px, 9999px, 32px, 0);
    transform: skew(0.75deg);
  }
  35% {
    clip: rect(62px, 9999px, 17px, 0);
    transform: skew(0.86deg);
  }
  40% {
    clip: rect(17px, 9999px, 93px, 0);
    transform: skew(0.69deg);
  }
  45% {
    clip: rect(55px, 9999px, 87px, 0);
    transform: skew(0.24deg);
  }
  50% {
    clip: rect(2px, 9999px, 46px, 0);
    transform: skew(0.43deg);
  }
  55% {
    clip: rect(81px, 9999px, 50px, 0);
    transform: skew(0.97deg);
  }
  60% {
    clip: rect(89px, 9999px, 42px, 0);
    transform: skew(0.5deg);
  }
  65% {
    clip: rect(40px, 9999px, 29px, 0);
    transform: skew(0.26deg);
  }
  70% {
    clip: rect(2px, 9999px, 76px, 0);
    transform: skew(0.78deg);
  }
  75% {
    clip: rect(45px, 9999px, 70px, 0);
    transform: skew(0.65deg);
  }
  80% {
    clip: rect(34px, 9999px, 49px, 0);
    transform: skew(0.16deg);
  }
  85% {
    clip: rect(58px, 9999px, 80px, 0);
    transform: skew(0.01deg);
  }
  90% {
    clip: rect(58px, 9999px, 70px, 0);
    transform: skew(0.82deg);
  }
  95% {
    clip: rect(89px, 9999px, 29px, 0);
    transform: skew(0.01deg);
  }
  100% {
    clip: rect(21px, 9999px, 91px, 0);
    transform: skew(0.01deg);
  }
}
@keyframes glitch-skew {
  0% {
    transform: skew(-3deg);
  }
  10% {
    transform: skew(-1deg);
  }
  20% {
    transform: skew(-2deg);
  }
  30% {
    transform: skew(-3deg);
  }
  40% {
    transform: skew(0deg);
  }
  50% {
    transform: skew(4deg);
  }
  60% {
    transform: skew(-3deg);
  }
  70% {
    transform: skew(-2deg);
  }
  80% {
    transform: skew(0deg);
  }
  90% {
    transform: skew(2deg);
  }
  100% {
    transform: skew(5deg);
  }
}