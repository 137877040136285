body{
 margin: 0px;
 padding: 0px;
 overflow-x: hidden;
/*  height: 100vh; */
}
:root{
  --colore-testo-footer: rgb(255, 255, 255);
  --colore-footer: rgb(22, 22, 22);
  --colore-sfondo: rgba(238, 228, 185, 0.87);
  --colore-menu: rgb(29, 29, 29);
  --colore-menu-chiuso: rgb(12, 12, 12);
  --colore-hover: rgb(226, 50, 6);


--dimensione-font-footer2: font-size;


--gradiente-viola: linear-gradient(to bottom, #480333, #440036, #3e0039, #38003d, #300040, #29033f, #22043d, #1b053b, #170534, #14042c, #100324, #0b011c);
--gradiente-verde: linear-gradient(90deg, rgb(6, 255, 205) 0%, rgb(12, 129, 82) 38%, rgb(0, 0, 0) 100%);;
}

.App {
  text-align: center;
  position: relative;
  z-index: 2;
 
  /*  background-image: linear-gradient(to bottom, #480333, #440036, #3e0039, #38003d, #300040, #29033f, #22043d, #1b053b, #170534, #14042c, #100324, #0b011c);  */
 background-image: var(--gradiente-viola);
/*  background-color: rgb(33, 32, 32);  */
min-height: 100vh;
 margin-bottom: -100vh;
}

/* ----------------------------- */

.reticolo{
  text-shadow: 8px 8px 10px #0000008c;
  background-color: #0cad8a;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%239C92AC' fill-opacity='0.25' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"), linear-gradient(to right top, #30153dd9, #2b2c31b7, #211f22, #151314, #08845df9);
height: 100%;
width: 100%;
   position: fixed;
  bottom: 0px;
  right: 0;
  z-index: 1; 
  opacity: 0.7;
}




/*----- footer -----*/

.footer{
 /*  background-color: #05020c; */
padding-left: 35px;
padding-right: 35px;
  height: 10vh;
  width: 100%;
  color: white;

  position: fixed;
  bottom: 0;
background-color: #101111;

  bottom: 0;
  z-index: 4;
  display: flex;

}
.col1{width: 20vw;}
.col2{width: 60vw;}
.col3{width: 20vw;}

.col1 > img{
  margin-top: 5px;
  padding: 0px;
position: relative;
right: 50px;
width: 123px;
}
 .col1 > p{
position: relative;
top: 25px
} 

.col3 > p{
font-size: 12px;
padding-top: 8px;
}






