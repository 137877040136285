.menu{
    width: 100%;
    color: black;
    position: relative;
    z-index: 4;
    background-color: rgba(0, 0, 0);
    height: 8vh;
    display: flex;
    padding-left: 20px;
    padding-right: 290px;
   
}
.logoMenu{
    margin-top: 10px;
    display: flex;
}
.logoMenu > img{
   height: 5vh;
}

.logoMenu p{
    font-size: 22px;
   padding-left:15px;
   margin-bottom: 20px;
   color: white;
}
.mottoMenu{
    font-size: 11px;
    color: white;
    position: relative;
    top: 35px;
    right: 120px;
    margin-top: 5px;
    color: rgb(5, 200, 200);
}


.spazioMenu{
 justify-content:flex-end;
 position: absolute;
 right: 10px;
}


.listaMenu{
display: flex;
padding-right: 30px;

} 
ul li{
    list-style: none;
    margin-right: 15px;
    padding-top: 20px;
    color: white;
}
ul a li:hover{
    color: rgb(5, 184, 160);
}
a{
    text-decoration: none;   
}
a h3 {color: white; }
a .logoMenu :hover{color: rgb(5, 184, 160); }

.listaMenuMobile{
    display: none;
}

.iconaToggleMenu{
    color: white;
}

.bottMobile{
  /*   display: flex;
    justify-content: flex-end; */
    position: absolute;
    right: 0;   
    padding-right: 30px;
    display: none;
}
#bottoneMobile{
 font-size: 33px;
 position: relative;
top: 10px;
 margin-bottom: -10px;
 cursor: pointer;
}





/* **********************************
------ MEDIA QUERY PER IL MENU TABLET-----
********************************** */
@media screen and (max-width: 740px) {

.bottMobile{

    display: block;
}

.spazioMenu{
    display: none;
}


/* MENU APERTURA E CHIUSURA */
#menu-mobileClose{
    display: none;
}
#menu-mobileOpen{
    display: block;
}
.menuMobile{
    position: absolute;
   right: 0px;
   top: 65px;
    margin: 0;
    height: 48vh;
    width: 100vW;
    background-color: rgba(10, 10, 10, 0.884);
    z-index: 10;
}
.listaMenuMobile{
    margin-top: 7vh;
  display: block;
}
.listaMenuMobile li{
 font-size: 24px;
 text-align: center;
 list-style: none;

 color: aliceblue;
width: 200px;
margin: 0 auto;

}


}

/* **********************************
------ MEDIA QUERY PER IL MENU MOBILE -----
********************************** */
@media screen and (max-width: 640px) {
.iconaToggleMenu{
    display: block
}
.spazioMenu{
    display: none;
}


/* MENU APERTURA E CHIUSURA */
#menu-mobileClose{
    display: none;
}
#menu-mobileOpen{
    display: block;
}
.menuMobile{
    position: absolute;
   right: 0px;
   top: 55px;
    margin: 0;
    height: 48vh;
    width: 100vW;
    background-color: rgba(10, 10, 10, 0.884);
    z-index: 10;
}
.listaMenuMobile{
    margin-top: 4vh;
  
}
.listaMenuMobile li{
 font-size: 23px;
 text-align: center;
 list-style: none;

 color: aliceblue;
width: 200px;
margin: 0 auto;
margin-top: 20px;
position: relative;
right: 20px;
}

li.active{
  background-color: rgb(0, 153, 164);
  color: black;
  border-radius: 10px;
 
  font-weight: bold;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}



.logoMenu {
    width: 300px;
}
.mottoMenu{
    display: none;
}

} /* FINE MEDIA QUERY MENU */


