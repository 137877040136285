
/* media query */
@media screen and (max-width: 640px) {
body{

overflow-x: hidden !important;

} 

  .reticolo{
   
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 0;
  }


/* **** MENU MENU **** */
.menu{
  background-color: rgb(16, 16, 16);
  height: 7,3vh !important;
}
.logoMenu > p {
  padding-top: 6px;
}
.bottMobile{
  padding-top: 5px;
}
/* **** bottone torna indietro **** */
.bottoneBack{
  display: none;
}
.bottServiziMobile{
  display: block !important;
  position: fixed;
  bottom: 2vh;
  z-index: 5 !important;
  background-color: rgba(240, 7, 96, 0.689);
  border-radius: 20px;
  height: 6vh ;
left: 2vw;
}
.bottServiziMobile > p{
  font-size: 30px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  position: relative;
 bottom: 4px

}
.BottLavoriPortfolio{
display: none;
}
.bottServiziMobileweb{
  display: block;
  position: relative !important;
z-index: 4 !important;
  bottom: 0;
  left: 10px;
  background-color: rgba(240, 7, 96, 0.689);
  border-radius: 20px;
  height: 6vh ;
  margin-bottom: 2vh;
}
.bottServiziMobileweb > p{
  font-size: 30px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  position: relative;
 bottom: 4px

}


/* ***********************
 **** FOOTER ****
 ********************* */



.footer{
  position: absolute;
  bottom: 0;
background-color: #010807;
  bottom: 0;
  display: block;
  height: auto;
  z-index: 4;

}
.col1{width: 100%;}
.col2{display: none;}

.col3{width: 80%; margin: 0 auto;}

.col1 > img{
  margin-top: 20px;
  padding: 0px;
position: inherit;
width: 130px;
display: none;
}
 .col1 > p{
position: relative;
top: 25px
} 

.col3 > p{
font-size: 13px;
padding-top: 15px;
padding-bottom: -23px;
}

/* ***********************
 **** HOME PAGE ****
 ********************* */

 .titolo_home{
  font-size: 3.9em !important;
  margin-top: -10px;
  margin-bottom: 5px;
}
.sub{
  font-size: 15px !important;
}
 .homeDesign{
  margin-top: 30px;
  display: block;
  padding-left: 5%;
  padding-right: 5%;
}
.metaBox{
/*   height: 70vh; */
  width: 100% !important;
  text-align: center; 
}
.workhome{
  display: none;
}
.desc_home{
  display: none;
}
.sessRqContenitore{
  width: 100%;
padding: 0;

 /*  padding-bottom: 180px !important; */
 }
 .sessRq{
  height: 20vh;
  width: 43vw;
/*   border: 3px solid magenta; */
  margin-right: 6px;
  margin-left: 0px;
  
  }
  
 .RqMobile{
/*   position: inherit !important;

border: 2px solid rgb(255, 255, 255) !important;
margin-bottom: -55px; */
z-index: 2;
height: 100%;
width: 100%;
  border: 2px solid rgb(226, 226, 226); 
}
.dentroRq h2{
font-size: 26px !important;
} 
.scrittaRq{
  padding-top: 40% !important;
}

/* ***********************
 **** Servizi ****
 ********************* */
.sessRq{
  margin-top:13px ;
}
.Cont_rq_servizi{
margin-bottom: -200px !important;
}
.scrittaRq > .riquadroMenu{
margin-top: -30px;
}


/* ***********************
 **** LAVORI Siti Web ****
 ********************* */
.spazioWeb{
  display: block;
  display: block !important;
  height: auto !important;
  overflow: hidden;
}
.sfondoWeb{
  position: relative;
  bottom: 160px;
  width: 100%;
  height: auto;
  padding-top: 30px;
  padding-bottom: 20px;
}
.Rqservizi{
  width: 100%;
  height: auto;
  position: relative;
  bottom: 170px;
  
}

.descWeb h2{
  font-size: 40px;
  letter-spacing: 4px;
}

.descWeb h3{
  font-size: 23px;
  color: rgb(19, 213, 206);
  letter-spacing: 5px;
}
.Rqservizi{
  justify-content: center;
  margin-bottom: 40px !important;
}
.riquadroServ_web{
  width: 140px !important;
  height: 100px !important;
}
.testo_tipi_Siti{
  padding-left: 3%;
  padding-right: 3%;
  margin-bottom: -20px;
}

.tipiSiti{
  padding: 15%;
  font-size: 18px;
  color: white;
margin-bottom: -100px !important;
}


.sfondoModal{
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.73);
  position: fixed;
  top: 0;
  z-index: 9;
}
.sfondMod{
  position: fixed;
}

.scheda{
  margin: 0 auto;
  width: 92%;
  height: 73vh;
  position: relative;
  z-index: 11;
  background-color: rgb(255, 255, 255);
 /*  font-size: 50px; */
  border-radius: 10px;
  margin-top: 100px;
  overflow-y:scroll;
  overflow-x: hidden;   
}


.topScheda{
  position: fixed;
  width: 92.5%;
  
  margin-bottom: 50px !important;
  overflow: hidden !important;
  
}
.bottone_chiudi{
  position: relative;
  z-index: 5;
  font-size: 18px;
  color: white;
  font-weight:bold;
  background-color: rgb(255, 0, 64);
  position: absolute;
  padding: 8px 16px;
  right: 0;
  top: 0;
  overflow: hidden !important;
  /* margin-right: -40px;  */
}

.bottone_chiudi:hover{
  background-color: rgb(12, 215, 215);
}

.riq_web_img{

margin: 0 auto;
margin-top: 80px;
width: 100%;
height: 230px;
object-fit: cover;
margin-bottom: 20px;
}

.riq_web_titolo{
  margin: 0 auto;
padding-bottom: 16px;
padding-top: 12px;
padding-right: 10%;
text-transform: uppercase;
font-size: 16px;

  color: rgba(255, 255, 255, 0.841);
  background-color: rgb(35, 36, 36);
  margin-bottom: 30px;
}

.riq_web_testo{
  color: rgb(235, 11, 56);
text-transform: uppercase;
padding-left: 5%;
padding-right: 5%;
margin-bottom: 30px;
}

.riq_web_desc{
  color: rgb(0, 0, 0);
  width: 90%;
 margin: 0 auto;
 margin-bottom: 30px;
}

.riq_web_prezzo{
  color: rgb(255, 255, 255);
}
.testoPreventivo{
  width: 90% ;
  margin-bottom: -70px;
  margin-top: 40px;
}

/* ***********************
 **** LAVORI GRAFICA ****
 ********************* ****/
 .spazioGrafica{
  display: block !important;
height: auto !important;
overflow: hidden;
padding-bottom: 130px;
}

.testo_portfolio_loghi{
  padding-left: 10px;
  padding-right: 10px;
}

.testo_portfolio_loghi h1{
  font-size: 60px !important;
}

 .sfondoGrafica{
width: 100%;
height: auto !important;
position: relative;
bottom: 170px;
padding-top: 20px;
padding-bottom: 20px;

}
.riquadroServ_grafica{
  width: 150px !important;
  height: 100px !important;  
}
.Rqservizi_Grafica{
  margin: 0 auto;
margin-bottom: 860px !important; 
width: 100% !important;
justify-content: center;
}
/* .riq_grafica_img{
width: 100%;
height: 210px;
object-fit: cover;
margin-top: 40px;
margin-bottom: 30px;
} */
.riq_grafica_img_scheda{
max-height: 450px !important;
width: 100%;
padding: 0;
margin-top: 40px;
margin-bottom: 80px;
 }
.riq_grafica_img2_scheda{
  width: 100% !important;
  height: auto !important;
  margin-top: -80px !important;
  margin-bottom: 20px;
}

.riq_grafica_img_scheda_gr{
  max-height: 450px !important;
  width: 100%;
  padding: 0;
  margin-top: 40px;
  margin-bottom: 80px;
   }
  .riq_grafica_img2_scheda_gr{
    width: 100% !important;
    height: auto !important;
    margin-top: -80px !important;
    margin-bottom: 20px;
  }



.img-quadrati-portfolio{
  max-height: 160px !important;
  object-fit: cover !important;
  
}
/* ***********************
 ***** PORTFOLIO Servizi ***
 *********************** */
.Cont_rq_portfolio{
justify-content: center;
width: 90%;
height: 100%;
position: relative;
} 

.Rq_p{
  z-index: 2;
  margin-top: 20px;
  min-height: 100%;
  min-width: 120%;
  border: 2px solid rgb(226, 226, 226); 
}

/* ***********************
 ***** TIPI DI GRAFICA ***
 *********************** */
 .riq_grafica_img{
  margin-top: 0px;
  width: 100%;
height:fit-content;
  object-fit: cover;
  margin-top: 45px;
 padding-bottom: 100px !important;
 }

.Cont_rq_tipiSito{

 } 

.Rq_portfolio_grafiche{
position: inherit !important;
}


/* ***********************
 ***** PORTFOLIO LOGHI ***
 *********************** */
 .spazioLoghi{

 }

 .testo_portfolio_loghi{
  margin-bottom: 35px;
 }
 .Rqservizi_loghi {
margin: 0 auto;
padding-bottom: 1400px !important; 
margin-bottom: -100px !important;
width: 100% !important;
justify-content: center;
}
 .Rq_loghi {
  max-width: 160px !important;
  max-height: 170px !important;
  /* margin: 0px !important; */

 }

 .riq_grafica_img_logo{
  margin-top: 30px;
width: 100% !important;

 }
.riq_grafica_img_logo2{
  width: 100% !important;
}

/* ***********************
 ***** PORTFOLIO SITI WEB***
 *********************** */

 .testo_portfolio_loghi{
  margin-top: 40px;
 }
.cont_rq_siti{
  display: block !important;
padding-bottom: 120px !important;
  margin-bottom: -50px !important; 

}
.RqSiti{
  width: 90% !important;
  background-color: rgba(0, 0, 0, 0.179) !important;
  margin-bottom: 30px !important;
  height: 24vh !important;
  display: flex;
  border-radius: 0px !important;
}
.descSiti{
  margin-top: 90%;
  background-color:  rgba(0, 255, 255, 0.786);
  font-size: 14px !important;
  width: 100%;
  font-weight: bold;
  letter-spacing: 1px !important;
  height: 30px;
 
}
/* .descSiti p{

  padding-bottom: -150px !important;
} */



/* ***********************
 ***** BIOOO ***
 *********************** */
 .pagina_bio{
  margin-top: 20px;
 }
 .contenitoreBio{
  margin: 0 auto;
  margin-top: 20px;
 position: relative;
 z-index: 2;
 width: 95%;
}
 .parteSkills{
  min-height: auto;
  position: relative;
  z-index: 2;
  bottom: 60px;
  margin-bottom: 100px;
 }
 .logoBio img{
  width: 270px;
  bottom: 30px;
}
.programmingBio{
  width: 90%;
}
.graphicsBio {
  width: 90%; 
}

/* ***********************
 ***** CONTATTI MOBILE ***
 *********************** */
.sessContatti{
  margin: 0 auto;
}


/* ***********************
 ***** BOTONE WP ***
 *********************** */
 .bottoneWP{
  width: 60px;
  height: 60px;

  position: fixed;
  z-index: 6;
  bottom: 1vh;
  right: 1.2vw;
}
.simboloWp{
  font-size: 42px;
  padding: 4px 8px 4px 8px;
border-radius: 10px;
  color: white;
  background-color: rgb(7, 191, 7);
}




} /* FINE MEDIAQUERY */


/* FINE SCHERMI GRANDI */
@media screen and (min-width: 1600px) {
  .bottServiziMobile{
    display: none !important;
  }

  .metaBox{
    padding-top: 5%;
}
.bottoneBack{
   bottom: 95px !important;
  }

.BottLavoriPortfolio{
margin-bottom: 150px !important;
margin-top: 50px !important;
}
.RqSiti{
height: 350px !important;
}

.col1 > img{
width: 138px;
padding-top: 5px;
}


} /* fine responsive mobile */





/* RESPONSIVE MOBILE S8 MOBILE STRETTO */

@media screen and (max-width: 500px) and (max-height: 740px) {
  

  .bottServiziMobile{
    display: block;
    position: fixed;
    bottom: 2vh;
    z-index: 5;
    background-color: rgba(240, 7, 96, 0.689);
    border-radius: 20px;
    height: 6vh ;
  left: 2vw;
  }
  .bottServiziMobile > p{
    font-size: 26px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    position: relative;
   bottom: 4px
  
  }

/* ***********************
 ***** BOTONE WP ***
 *********************** */
 .bottoneWP{
  width: 60px;
  height: 60px;

  position: fixed;
  z-index: 6 !important;
  bottom: 0vh;
  right: 0.5vW;
}
.simboloWp{
  font-size: 35px;
  padding: 4px 8px 4px 8px;
border-radius: 10px;
  color: white;
  background-color: rgb(7, 191, 7);
}

  /* ***** MENU ***** */
  
  .Cont_rq_portfolio{
    margin-top: -50px;
  }

  .sessRqContenitore{
    width: 100%;
    margin-top: -30px;
  padding: 0;
   }

  } /* fine responsive mobile stretto */


/* @media (min-height: 680px), screen and (orientation: portrait) {
  
} */