/* ------ HOMEPAGE ------- */
.homeDesign{
    display: flex;
    padding-left: 5%;
    padding-right: 5%;
  
}
.metaBox{
    height: auto;
    width: 50% !important;
    text-align: center; 
}

.sessRqContenitore{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
max-height: 70vh;
margin-top: 8vh;
width: 40vw;
}
.sessRq{
display: flex;
flex-wrap: wrap;
height: 43%;
width: 43%;
margin-left: 25px;
}
.titolo_home{
    color: white;
    position: relative;
    z-index: 3;
}
.social_link_home{
    margin: 0 auto;
      margin-top: 10px;
      margin-bottom: 50px;
      color: white;
      position: relative;
      z-index: 2;
      font-size: 34px;
      width: 100%;
}
    
.social_link_home :nth-child(-n + 3){
margin-left: 10px;
margin-right: 10px;
}
    
.social_link_home :nth-child(-n + 3):hover{
color: rgb(8, 154, 149);
cursor: pointer;
}
.workhome{

    margin-top: 40px;
}
.workhome h2{
    position: relative;
    z-index: 10;
    color: white;
    font-size: 30px;
    margin-bottom: -5px;
}
.desc_lavori {
    color: rgb(19, 213, 206);
    position: relative;
    z-index: 10;
  margin-bottom: 25px;
}
.desc_home{
    width: 60%;
    margin: 0 auto;
    position: relative;
    z-index: 10;
    color: white;
    margin-bottom: -5px;
    font-size: 13px;
}


/* ********************* */
/* *****---- BIO ----***** */
.pagina_bio{
 color: white;

}
.contenitoreBio{
    margin: 0 auto;
    margin-top: 20px;
   position: relative;
   z-index: 2;
   width: 80%;
 
}
.contenitoreBio > p{

  padding: 6%;
  font-size: 14px;
    position: relative;
    z-index: 2;
    line-height: 30px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 5%;
 }
.paragrafo_bio{
    padding-top: 30px;
    padding-left: 15%;
    padding-right: 15%;
text-align: center;
    margin: auto;
}
.logoBio{
position: relative;
z-index: 4;
margin-bottom: 40px;
}
.logoBio img{
    position: relative;
    bottom: 35px;
    margin-bottom: 20px;  
    width: 220px;
}
.programmingBio{
    width: 70%;
    margin: 0 auto;
    margin-top: 30px;
    position: relative;
    z-index: 2;
    color: white;
}
.programmingBio p{
    color: rgb(255, 255, 255);
}
.programmingBio h4{
    font-size: 30px;
}

.graphicsBio {
    width: 70%;
    margin: 0 auto;
    margin-top: 50px !important;    
}
.graphicsBio h4{
    font-size: 30px;
}
.graphicsBio p{
    color: rgb(255, 255, 255);
}

.loghiStrumenti img{
    width: 70px;
}
.graphicsBio{
    margin-top: 40px;
    position: relative;
    z-index: 2;
    color: white;
}
/* ********************* */
/*  ---- WEB ---- */
.spazioWeb{
    display: flex;
}
.sfondoWeb{
    margin-top: -60px;
 position: relative;
    z-index: 3; 
    width: 30%;
    background-color: rgba(0, 0, 0, 0.685);
    background-image: url('../../public/img/sfondiHome/program.jpg');
    background-size: cover;
    height: 90vh;
    background-blend-mode:multiply;
}

.sfondoWeb img{
 margin-top: 120px;
 width: 380px;
 margin-bottom: -20px;
}


.descWeb{
    color: white;
    text-align: center;
}

.descWeb h2{
    font-size: 60px;
   /*  font-weight: bold; */
  /*   padding-top: 10px; */
    letter-spacing: 4px;
    margin-top: 55%;
}
.barraDiv{
 position: relative;
    height: 5px;
    background-color: white;
    margin-bottom: 10px;
}
.descWeb h3{
    font-size: 27px;
    color: rgb(19, 213, 206);
    letter-spacing: 7px;
}

.Rqservizi {
justify-content: center;
 height: 30vh;
 margin: 0 auto;
  }

.riquadroServ_web{
    position: relative;
    z-index: 3;
    margin-top: 30px;
    background-color: white;
    border: 2px solid white;
 height: 120px !important;
    width: 180px !important;
    margin-right: 20px !important;
    margin-left: 20px !important;
    border-radius: 10px;
    background: rgb(11,241,209);
    background: linear-gradient(51deg, rgba(11,241,209,0.8268557422969187) 0%, rgba(0, 0, 0, 0.695) 15%, rgba(0, 0, 0, 0.709) 81%, rgba(0,212,255,0.8240546218487395) 100%);
}

.riquadroServ_web:hover{
    position: relative;
    z-index: 5;
    cursor: pointer;
   /*  right: 1vh; */
    bottom: 2vh;
    box-shadow:
    0 0 10px 5px #ffffffcb,  /* inner white */
    0 0 30px 10px rgba(255, 0, 255, 0.753), /* middle magenta */
    0 0 60px 25px rgba(0, 255, 255, 0.692); /* outer cyan */
   transform: rotate(2deg) !important;
  }

  .tipiSiti{
    padding: 15%;
    font-size: 20px;
    color: white;
margin-bottom: -100px !important;
  }


.sfondoModal{
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.73);
    position: fixed;
    top: 0;
    z-index: 9;
}
.sfondMod{
    position: fixed;
}

.scheda{
    margin: 0 auto;
    width: 40%;
    height: 70vh;
    position: relative;
    z-index: 10;
    background-color: rgb(255, 255, 255);
   /*  font-size: 50px; */
    border-radius: 10px;
    margin-top: 100px;
    overflow-y:scroll;
    overflow-x: hidden;   
}


.topScheda{
    position: fixed;
    width: 40%;
    margin-bottom: 50px !important;
    overflow: hidden !important;
    
}
.bottone_chiudi{
    position: relative;
    z-index: 5;
    font-size: 18px;
    color: white;
    font-weight:bold;
    background-color: rgb(255, 0, 64);
    position: absolute;
    padding: 8px 16px;
    right: 0;
    top: 0;
    overflow: hidden !important;
    /* margin-right: -40px;  */
}

.bottone_chiudi:hover{
    background-color: rgb(12, 215, 215);
}

.riq_web_img{

margin: 0 auto;
margin-top: 80px;
width: 100%;
height: 230px;
object-fit: cover;
margin-bottom: 20px;
}

.riq_web_titolo{
    margin: 0 auto;
 padding-bottom: 11px;
 padding-top: 10px;
 text-transform: uppercase;
 font-size: 21px;
    color: rgba(255, 255, 255, 0.841);
    background-color: rgb(35, 36, 36);
    margin-bottom: 30px;
}

.riq_web_testo{
    color: rgb(235, 11, 56);
text-transform: uppercase;
padding-left: 5%;
padding-right: 5%;
margin-bottom: 30px;
font-size: 22px;
}

.riq_web_desc{
    color: rgb(0, 0, 0);
    width: 90%;
   margin: 0 auto;
   margin-bottom: 30px;
}

.riq_web_prezzo{
    background-color: rgb(235, 11, 56);
    color: white;
    padding: 8px 5px 8px 5px;
    border-radius: 5px;
    margin-bottom: 40px;
    text-transform: uppercase;
    font-weight: bold;
}

.immag{
    width: 200px;
    height: 200px;
    position: relative;
    z-index: 20;
}

.testo_tipi_Siti{
    margin-top: 40px;
    width: 100%;
    display: block;
    text-align: center;
    position: relative;
    z-index: 3;
    color: white;
}
.testoPreventivo{
    position: relative;
    z-index: 4;
background-color: rgba(0, 0, 0, 0.62);
margin-top: 25px;
border-radius: 8px;
width: 40vw;

}
.testoPreventivo p{
   
    font-size: 16px;
    color: rgb(0, 225, 210);
  
padding: 7px 11px 4px 11px;
margin-bottom: 5px;
}

/************ SERVIZI ******************/

.Servizi-template{
    display: flex;
} 

.Cont_rq_servizi{
    display: flex;
  flex-wrap: wrap;
    justify-content: center;
/*   justify-content: space-evenly */
margin-top: 10px;
width: 50%;
}
    
/* ---************ GRAFICA ************---- */
.sfondoGrafica{
    margin-top: -60px;
    position: relative;
       z-index: 3; 
       width: 30%;
      
       background-color: rgba(0, 0, 0, 0.685);
       background-image: url('../../public/img/sfondiHome/graficaSfond.jpg');
       background-size: cover;
       height: 90vh;
       background-blend-mode:multiply;
}
.riquadroServ_grafica{
    position: relative;
    z-index: 3;
    margin-top: 20px;
    background-color: white;
    border: 2px solid white;
 height: 120px !important;
    width: 180px !important;
    margin-right: 20px !important;
    margin-left: 20px !important;
    border-radius: 10px;
    background: rgb(11,241,209);
    background: linear-gradient(51deg, rgba(241, 11, 126, 0.827) 0%, rgba(0, 0, 0, 0.695) 15%, rgba(0, 0, 0, 0.709) 81%, rgba(241, 11, 126, 0.827) 100%);
}

.riquadroServ_grafica:hover{
    position: relative;
    z-index: 5;
    cursor: pointer;
   /*  right: 1vh; */
    bottom: 2vh;
    box-shadow:
    0 0 10px 5px #ffffffcb,  /* inner white */
    0 0 30px 10px rgba(255, 0, 255, 0.753), /* middle magenta */
    0 0 60px 25px rgba(0, 255, 255, 0.692); /* outer cyan */
   transform: rotate(2deg) !important;
  }

.riq_grafica_immagine{
width: 100%;
margin-top: 40px;
margin-bottom: 20px;
/* object-fit: cover; */
}

/*---  PORTFOLIO  ---*/
.Cont_rq_portfolio{
    margin-top: 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 40vw;

}

.Rq_p{
    z-index: 2;
    min-height: 100%;
    min-width: 100%;
    border: 2px solid rgb(226, 226, 226); 
}

/*---********** PAGINA DEI CONTATTI *********---*/
.sessContatti{
    margin-top: 12vh;
    position: relative;
    z-index: 3;
    color: white;
    margin-left: 8vw;
    padding-bottom: 100px;
   
}
.sessContatti > p{
    background-color: rgba(0, 0, 0, 0.77);
    border-radius: 10px;
    padding: 10px 20px 10px 20px;
    font-size: 18px;
  color: white;
  margin-bottom: 24px;
}
.iconeContatti{
    font-size: 28px;
    margin-right: 15px;
}
.socialContatti p{
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: -5px;

}
.socialContatti{
    font-size: 49px;
}
.icone_socialContatti{
    margin-right: 8px;
    margin-left: 12px;
}

.icone_socialContatti{
    color: rgb(0, 176, 158);
}
.icone_socialContatti:hover{
    color: rgb(224, 0, 82);
}