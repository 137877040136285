

.bottoneBack{
margin-top: 50px;
position: fixed;
z-index: 6 !important;
 bottom: 75px;
 left: 0;
 z-index: 50;
 clip-path: polygon(0 15%, 0% 100%, 100% 100%);
 color: black;
 font-weight: bold;
 background-color: rgb(11, 208, 208);
 height: 140px;
 border: 2px solid white;
}

.bottServiziMobile{ 
  position: fixed;
  z-index: 5 !important;
  display: none !important;
}


.bottoneBack p{
position: relative;
top: 30px;
right: 20px;
     transform:rotate(43deg);
    }

.bottoneBack:hover{
  border: 6px solid rgb(0, 255, 229);
  clip-path: polygon(0 15%, 0% 100%, 100% 100%);
    background-color: rgba(22, 23, 23, 0.883);
    box-shadow:
    0 0 10px 5px #ffffffcb,  /* inner white */
    0 0 20px 8px rgba(255, 0, 255, 0.753), /* middle magenta */
    0 0 40px 15px rgba(0, 255, 255, 0.692); /* outer cyan */
    }





/* ---- SCOLLBAR ------ */
    ::-webkit-scrollbar {
        width: 15px;
       /*  background: rgb(4, 70, 203);  */
      }
      
      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
      }
       
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: rgb(235, 8, 83); 
        border-radius: 10px;
      }
      
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #001bb3; 
      }


   
      
/* ***********************
 ***** BOTONE WP ***
 *********************** */
 .bottoneWP{
  width: 60px;
  height: 60px;
  
  position: fixed;
  z-index: 6;
  bottom: 11vh;
  right: 3vh;
}
.simboloWp{
  font-size: 55px;
  padding: 5px 10px 5px 10px;
border-radius: 10px;
  color: white;
  background-color: rgb(7, 191, 7);
}


/* ---- COOKIE CONSENT ------ */
@media screen and (max-width: 640px) {

}
