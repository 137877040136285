.spazioGrafica{
    width: 100vw;
    margin: 0 auto;
}

.Rqservizi_Grafica{
    width: 80vw ;
    margin: 0 auto ;
    margin-bottom: 40px ;
}
.img-quadrati-portfolio{
    max-height: 190px ;
    object-fit: cover ;
    
  }

  .riq_grafica_img_scheda_gr{
  
    width: 100%;
    padding: 0;
    margin-top: 40px;
    margin-bottom: 80px;
    object-fit: contain;
     }
    .riq_grafica_img2_scheda_gr{
      width: 100% ;
      height: auto ;
      margin-top: -80px ;
      margin-bottom: 20px;
      object-fit: contain;
    }

/* ************************
-- PAGINA dei lavori loghi --
************************ */


.testo_portfolio_loghi{
    position: relative;
    z-index: 2;
    color: white;
 width: 100%;
}
.testo_portfolio_loghi h1{
    font-family: fontPersonalizzato;
    color: white;
    font-size: 80px;
}


.Rqservizi_loghi {
/*   display: flex; */
 margin: 0 auto;
 margin-bottom: 60px;
 margin-top: 30px;
 width: 80%;
  }

.Rq_loghi{
    position: relative;
    z-index: 3;
    background-color: white;
    cursor: pointer;
    height: 30vh !important;
    width: 30vh !important; 
    margin-top: 30px;
   /*  background: linear-gradient(51deg, rgba(11,241,209,0.8268557422969187) 0%, rgba(0, 0, 0, 0.695) 15%, rgba(0, 0, 0, 0.709) 81%, rgba(0,212,255,0.8240546218487395) 100%); */
   background-color: rgba(0, 0, 0, 0.699);
   margin-right: 10px;
   margin-left: 10px;
}
.Rq_loghi:hover{
    position: relative;
    z-index: 5;
   /*  right: 1vh; */
    bottom: 2vh;
    box-shadow:
    0 0 10px 5px #ffffffcb,  /* inner white */
    0 0 30px 10px rgba(255, 0, 255, 0.753), /* middle magenta */
    0 0 60px 25px rgba(0, 255, 255, 0.692); /* outer cyan */
   transform: rotate(2deg);
  }

.loghiBottoni{
  position: absolute;
  bottom: 0;
  padding-bottom: 8px;
  padding-top: 8px;
  width: 100%;
  z-index: 2;
  color: rgb(0, 0, 0);
  background-color: rgb(5, 202, 202);
  font-size: 15px;
}

.img-quadrati-portfolio{
    width: 100%;  
    object-fit: cover !important;
}


.BottLavoriPortfolio{
    margin: 0 auto;
margin-bottom: 90px;
   position: relative;
    z-index: 2;
  height: 45px;
    color: black;
    font-weight: bold;
    background-color: rgb(11, 208, 208);
    border: 2px solid white;  
}

.BottLavoriPortfolio:hover{
    border: 6px solid rgb(0, 255, 229);
 
      background-color: rgba(22, 23, 23, 0.883);
      box-shadow:
      0 0 10px 5px #ffffffcb,  /* inner white */
      0 0 20px 8px rgba(255, 0, 255, 0.753), /* middle magenta */
      0 0 40px 15px rgba(0, 255, 255, 0.692); /* outer cyan */
}
.riq_grafica_img_logo{
    width: 100%;
    
}
.riq_grafica_img_logo2{
    width: 100%;
   margin-bottom: 40px;
}
/* ************************
-- PAGINA LAVORI SITI WEB --
************************ */


.sessSitiweb{
    position: relative;
    z-index: 2;
    width: 100%;
    
}
.testo_portfolio_loghi{
    margin-top: 30px;
}
.cont_rq_siti{
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    margin-bottom: 50px;
    justify-content: center;
}
.RqSiti{
  width: 28%;
  margin-left: 18px;
  margin-right: 18px;
  margin-bottom: 30px;
   height: 260px;
   border: 3px solid rgb(255, 0, 85);
   background-color: rgba(0, 0, 0, 0.4);
   background-size: cover;
   background-position: center;
   background-blend-mode: multiply;
   border-radius: 20px;
}
.RqSiti:hover{
    cursor: pointer;
    border: 6px solid rgb(0, 255, 229);
 position: relative;
 bottom: 10px;
      background-color: rgba(22, 23, 23, 0);
      box-shadow:
      0 0 10px 5px #ffffffcb,  /* inner white */
      0 0 20px 8px rgba(255, 0, 255, 0.753), /* middle magenta */
      0 0 40px 15px rgba(0, 255, 255, 0.692); /* outer cyan */
}


.descSiti{
    margin-top: 40%;
    background-color:  rgba(0, 255, 255, 0.786);
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1.4px;
padding-top: 5px;
padding-bottom: 5px;
}

.RqSiti:hover > .descSiti{
    background-color:  rgba(255, 0, 60, 0.786);
    color: white;
}

/* .img-quadrati-sitiweb{
  width: 100%;
  object-fit: cover;
} */
.riq_grafica_img{
    min-height: 100%;
    width: 100%;
}

.riq_grafica_img2{
    min-height: 80%;
  
    width: 100%;
    margin-bottom: 20px;
}
.riq_web_imgDue{
    margin-top: 55px;
width: 90%;
}


