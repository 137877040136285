
:root{  
 --imgRiquadro1: url('../../public/img/sfondiHome/bio3.jpg');
 --imgRiquadro2: url('../../public/img/sfondiHome/portfolioRQ.jpg');
 --imgRiquadro3: url('../../public/img/sfondiHome/program.jpg');
 --imgRiquadro4: url('../../public/img/sfondiHome/bio.jpg');  

/*  PAGINA /servizi */
 --imgRiquadro-lavoro-siti: url('../../public/img/sfondiHome/progr.jpg'); 
 --imgRiquadro-lavoro-grafica: url('../../public/img/sfondiHome/graficaLavoro.jpg'); 
 --imgRiquadro-lavoro-menu: url('../../public/img/sfondiHome/grDig.jpg'); 

/*  PAGINA /portfolio/tipografica */

--imgRiquadro-portfMenu-web: url('../../public/img/sfondiHome/sitiweb.jpg'); 
--imgRiquadro-portfMenu-tipoGrafica: url('../../public/img/sfondiHome/grafica.jpg'); 


--imgRiquadro-portfolio-loghi: url('../../public/img/sfondiHome/grDig.jpg');
--imgRiquadro-portfolio-grafiche: url('../../public/img/sfondiHome/dutch.jpg');


 --imgRiquadro-servizi: url('../../public/img/sevenmenu.png');
}
  

.Rq{
    z-index: 2;
min-height: 100%;
min-width: 100%;
  border: 2px solid rgb(226, 226, 226); 
}


.Rq:hover{
  position: relative;
  z-index: 5;
 /*  right: 1vh; */
  bottom: 2vh;
  box-shadow:
  0 0 10px 5px #ffffffcb,  /* inner white */
  0 0 30px 10px rgba(255, 0, 255, 0.753), /* middle magenta */
  0 0 60px 25px rgba(0, 255, 255, 0.692); /* outer cyan */
 transform: rotate(2deg);
}

.Rqservizio:hover{
  position: relative;
  z-index: 5;
  bottom: 2vh;
  box-shadow:
  0 0 10px 5px #ffffffcb,  /* inner white */
  0 0 30px 10px rgba(255, 0, 255, 0.753), /* middle magenta */
  0 0 60px 25px rgba(0, 255, 255, 0.692); /* outer cyan */

}



.Rq:hover > body{
overflow-x: hidden;
}
.Rq:hover > .dentroRq h2{
background-color: rgba(37, 220, 207, 0.522);
}

.Rq1{
   background-color: rgba(5, 5, 5, 0.575);
   background-image: var(--imgRiquadro1);
   background-size: cover;
   background-position: center;

 
}
.Rq2{
   background-color: rgba(5, 5, 5, 0.575);
   background-image: var(--imgRiquadro2);
   background-size: cover;
   background-position-y: bottom;
   background-position-x: right;
   position: relative;
 
 }
 .Rq3{
   background-color: rgba(5, 5, 5, 0.575);
   background-image: var(--imgRiquadro3);
   background-size: cover;
 }
 .Rq4{
    background-color: rgba(5, 5, 5, 0.575);
    background-image: var(--imgRiquadro4);
    background-size: cover;
    background-position: center;
    position: relative;
 
 }


 .Rq_servizi1{
  background-color: rgba(5, 5, 5, 0.575);
  background-image: var(--imgRiquadro-lavoro-siti);
  background-size: cover;
  background-position: center;

}
.Rq_servizi2{
  background-color: rgba(5, 5, 5, 0.575);
  background-image: var(--imgRiquadro-lavoro-grafica);
  background-size: cover;
  background-position: center;
  position: relative;

}
.Rq_servizi3{
  background-color: rgba(5, 5, 5, 0.575);
  background-image: var(--imgRiquadro-lavoro-menu);
  background-size: cover;
  background-position: center;
 position: relative;

}

.Rq_portfolio1{
  background-color: rgba(5, 5, 5, 0.575);
  background-image: var(--imgRiquadro-lavoro-siti);
  background-size: cover;
  background-position: center;
}
.Rq_portfolio2{
  background-color: rgba(5, 5, 5, 0.575);
  background-image: var(--imgRiquadro-lavoro-siti);
  background-size: cover;
  background-position: center;
}

.Rq_portfolio3{
  background-color: rgba(5, 5, 5, 0.575);
  background-image: var(--imgRiquadro-portfMenu-web);
  background-size: cover;
  background-position: center;
}
.Rq_portfolio4{
  background-color: rgba(5, 5, 5, 0.575);
  background-image: var(--imgRiquadro-portfMenu-tipoGrafica);
  background-size: cover;
  background-position: center;
}


.Rq_portfolio_loghi{
  background-color: rgba(5, 5, 5, 0.575);
  background-image: var(--imgRiquadro-portfolio-loghi);
  background-size: cover;
  background-position: center;
 position: relative;
/*   right: 20px;  */
}

.Rq_portfolio_grafiche{
  background-color: rgba(5, 5, 5, 0.575);
  background-image: var(--imgRiquadro-portfolio-grafiche);
  background-size: cover;
  background-position: center;
 position: relative;
 
}




 .wrapper2 {
  padding-top: 18vh;
}

.dentroRq{
   background-color: rgba(0, 0, 0, 0.55);
 height: 100%;
 }

.dentroRq:hover{
   background-color: rgba(0, 0, 0, 0.137);
 cursor: pointer;
 }

.dentroRq h2{
   color: white;
   margin: 0 auto;
   justify-content: center;
 font-size: 40px;
} 

.scrittaRq{
   color: white;
   padding-top: 35%;
}

.imgRQ{
  height: 210px;
  padding-bottom: 10px;
  margin-bottom: -200px !important;
}



